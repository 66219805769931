@import "~open-iconic/font/css/open-iconic-bootstrap.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
  font-family: "Avenir LT Std";
  src: url("/assets/fonts/AvenirLTStd-Roman.woff2") format("woff2"),
    url("/assets/fonts/AvenirLTStd-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/assets/fonts/AvenirLTStd-Book.woff2") format("woff2"),
    url("/assets/fonts/AvenirLTStd-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir LT Std";
  src: url("/assets/fonts/AvenirLTStd-Black.woff2") format("woff2"),
    url("/assets/fonts/AvenirLTStd-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@media (max-width: 500px) {
  .button-collection-ranking {
    font-size: 12px;
    padding: 4px;
  }

  .right-block-title {
    font-size: 14px !important;
  }

  .ranking-block-top-address {
    font-size: 8px;
  }

  .btn-title {
    font-size: 14px;
  }

  .search {
    .searchBlockInp {
      display: none;
    }
  }

  .ranking-block-top-address,
  .ranking-block-top-img,
  .ranking-block-top-network,
  .valuta {
    display: none;
  }

  .ranking-left-block {
    grid-template-columns: 1fr 3fr 2fr !important;
    font-size: 12px;
  }

  .left-first-block {
    grid-template-columns: 1fr !important;
  }
}

@media (max-width: 833px) {
  .credit-card-block,
  .cr-cr-lower-block {
    grid-template-columns: 1fr !important;
  }

  .pur {
    display: grid;

    .p-b-6 {
      max-width: 100% !important;
      text-align: center;
    }
  }
  .btn-purchase-block {
    display: grid !important;
    width: 100% !important;
    justify-content: center !important;

    .btn-purchase {
      margin: 10px 0px 0px 10px !important;
    }
  }
  .menu {
    display: none;
    margin-top: 20px;
    .item-menu {
      color: #ffffff;
      font-weight: bolder;
      margin-left: 25px;
      margin-top: 10px;
    }
  }

  .btnMobMenu {
    padding-bottom: 10px;
    display: block !important;
    background: transparent;
    position: fixed;
    width: 100%;
    height: auto;

    #btnControl {
      display: none;
    }

    .btnImg {
      img {
        width: 30px;
        height: auto;
      }
    }
  }

  .header {
    display: none !important;
    position: fixed;
    z-index: 10;
    width: 100%;
  }
}

@media (max-width: 1100px) {
  .content-purchase {
    grid-template-columns: 1fr !important;
  }
}

.credit-card-block {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;

  .credit-card-circle-block {
    margin-top: 30px;
    justify-items: center;
    display: grid;

    .purple-circle {
      width: 80px;
      height: 80px;
      display: block;
      background-color: #8781bd;
      margin-bottom: 20px;
      border-radius: 50%;
    }

    .yellow-circle {
      width: 80px;
      height: 80px;
      display: block;
      background-color: #fff79a;
      border-radius: 50%;
      margin-bottom: 20px;
    }

    .green-circle {
      width: 80px;
      height: 80px;
      display: block;
      background-color: #88d392;
      border-radius: 50%;
      margin-bottom: 20px;
    }

    .cr-cr-dsc {
      font-size: 12px;
      margin-top: 0.5rem;
    }

    .btn-cr-car {
      border: 2px solid #a6a0c9;
      padding: 3px 10px;
      border-radius: 20px;
      color: #213458;

      .dark-mode & {
        color: #ffffff;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.left-second-block {
  .credit-card-title {
    width: 100%;
    border-bottom: 2px solid #b7b7b747;
    color: #213458;
    font-size: 20px;
    font-weight: bold;

    .dark-mode & {
      color: #ffffff;
    }
  }

  .cr-cr-lower-block {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    .low-desc-bl {
      color: #213458;

      .dark-mode & {
        color: #ffffff;
      }

      .imp-txt {
        color: #213458;
        font-weight: bold;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 10px;
        margin-bottom: 10px;

        .dark-mode & {
          color: #ffffff;
        }
      }
    }
  }
}

.right-block-purchase {
  display: block !important;

  .right-block-title {
    width: 100%;
    color: #213458;
    font-weight: bold;
    font-size: 20px;
    border-bottom: 2px solid #b7b7b747;
    height: 35px;
    margin-bottom: 0.65rem;

    .dark-mode & {
      color: #ffffff;
    }
  }

  .ranking-block-top-img {
    img {
      border-radius: 50%;
      width: 30px;
      height: 30px;
    }
  }

  .ranking-block-top-network {
    img {
      width: 40px;
    }
  }
}

a {
  white-space: nowrap;
  transition: opacity 350ms ease-in-out;

  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
}

.rankings-content-block {
  width: 100%;
  display: grid;
  grid-template-columns: 5fr 1fr;
  margin-bottom: 0.75rem;

  .ranking-left-block {
    -webkit-box-shadow: 0px 0px 3px 1px rgba(#213458, 0.15);
    box-shadow: 0px 0px 3px 1px rgba(#213458, 0.15);
    border-radius: 20px;
    padding: 0px 15px;
    margin-top: 10px;
    font-size: 14px;
    display: grid;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    grid-template-columns: 1fr 1fr 4fr 4fr 3fr 1fr;

    .dark-mode & {
      -webkit-box-shadow: 0px 0px 3px 1px rgba(#fff, 0.15);
      box-shadow: 0px 0px 3px 1px rgba(#fff, 0.15);
    }

    .ranking-block-top-name {
      font-weight: bold;
      text-align: left;
      color: #213458;
      padding-left: 10px;
      white-space: nowrap; /* Запрещаем перенос строк */
      overflow: hidden; /* Обрезаем все, что не помещается в область */
      text-overflow: ellipsis; /* Добавляем многоточие */

      .dark-mode & {
        color: #ffffff;
      }
    }

    .ranking-block-top-balance {
      text-align: right;
    }
  }

  .ranking-right-block {
    margin-left: 10px;
    -webkit-box-shadow: 0px 0px 3px 1px rgba(#213458, 0.15);
    box-shadow: 0px 0px 3px 1px rgba(#213458, 0.15);
    border-radius: 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;

    .dark-mode & {
      color: #ffffff;
      -webkit-box-shadow: 0px 0px 3px 1px rgba(#fff, 0.15);
      box-shadow: 0px 0px 3px 1px rgba(#fff, 0.15);
    }

    .button-collection-ranking {
      margin: 0 auto;
      font-size: 12px;
      color: #213458;

      .dark-mode & {
        color: #ffffff;
      }
    }
  }
}

.swapLogo {
  width: 100px;
  margin-top: 40px;
}

.btn-buy-quickswap {
  margin-top: 10px;

  a {
    color: #3f86c5;
    border: 2px solid #3f86c5;
    border-radius: 20px;
    padding: 5px;
    font-weight: bold;
    &:hover {
      text-decoration: none;
    }
  }
}

.btn-buy-uniswap {
  margin-top: 10px;
  a {
    color: #ff007b;
    border: 2px solid #ff007b;
    border-radius: 20px;
    padding: 5px;
    font-weight: bold;
    &:hover {
      text-decoration: none;
    }
  }
}

.lfb {
  padding-bottom: 40px;
  display: grid;
  color: #213458;
  justify-items: center;

  .dark-mode & {
    color: #ffffff;
  }
}

.content-purchase {
  margin-top: 30px;
  display: grid;
  // grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  text-align: center;

  .left-block-purchase {
    display: grid;
    grid-row-gap: 30px;

    .left-first-block {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 30px;
    }
  }
}

.row.pur {
  .col-9 {
    max-width: 50%;
  }
}

.btn-purchase-block {
  display: flex;
  width: 50%;
  justify-content: flex-end;
  align-items: flex-end;

  .btn-purchase {
    position: relative;
    background-color: #5588e6;
    color: #ffffff;
    text-align: center;
    font-weight: bold;
    padding: 5px 40px;
    border-radius: 20px;
    margin-right: 32px;
    margin-bottom: 10px;
    min-width: 200px;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      opacity: 0.9;
    }

    &::after {
      background-image: url("/assets/images/right-arrow.png");
      background-size: 13px 10px;
      background-repeat: no-repeat;
      content: "";
      display: block;
      width: 30px;
      height: 30px;
      color: #ffffff;
      font-family: FontAwesome;
      position: absolute;
      right: 0;
      top: 11px;
      filter: invert(1);
    }
  }
}

.block-collection {
  width: 97%;
  padding-bottom: 30px;

  .header-collection-name {
    margin-left: -0.5px;
    display: block;
    color: #5588e6;
    padding: 4px;

    .dark-mode & {
      color: #ffffff;
    }
  }
  .collection-card-blocks {
    display: flex;
    flex-wrap: wrap;
  }
}

.btnMobMenu {
  .menuBTN {
    display: block;
    width: 17%;
  }
  display: none;
  padding: 0px 10px 20px 0px;
  background: transparent;
  background: #5588e6;
  position: fixed;
  z-index: 111;

  #btnControl {
    display: none;
  }

  .btnImg {
    img {
      position: absolute;
      width: 30px;
      height: auto;
      top: 25px;
      left: 25px;
    }
  }
  .search {
    .btn-menu {
      background: #fff !important;
      color: #5588e6 !important;
    }

    .searchInpLabel {
      &:before {
        content: "";
        display: block;
        width: 16px;
        background-size: 16px;
        height: 16px;
        background-image: url(/assets/images/magnification.svg);
        margin-left: -25px;
        position: absolute;
        top: 8px;
      }
    }
    .searchInp {
      border-radius: 20px;
      border: 2px solid #5588e6;
      margin-left: 10px;
      outline: none;
      padding: 5px 15px;
      height: 30px;
      width: 170px;
    }
    div {
      position: relative;
    }

    .item-menu {
      font-weight: bold;
    }

    display: flex;
    margin-top: 0px;
    justify-content: flex-end;
  }
}

#collectionList {
  display: grid;
  margin-left: 5px;

  .list {
    display: flex;
    width: 384px;
    border-radius: 12px;
    color: #ffffff;
    background: #5588e6;
    border-bottom: 2px solid #ffffff;
    justify-content: space-between;
    padding: 10px;
    font-size: 20px;
    margin-bottom: 10px;

    .firstblock {
      width: 40px;
    }

    .middleblock {
      text-align: left;
      width: 100%;

      a {
        color: #ffffff;
      }
    }
  }
}

.centrBtnBlock {
  margin-top: 50px;
  margin-bottom: 50px;
}

.centrBtn {
  span {
    margin: 0 auto;
  }
}

.header {
  position: fixed;
  z-index: 10;
  width: 100%;
}

.container-fluid {
  padding-top: 10px;
  min-height: 100vh;
}

.container-profile-fluid {
  min-height: unset !important;
}

.btn.btn-primary.btn-menu.just-center {
  background: #213458;
  color: #ffffff;
}

.title {
  font-weight: bold;
  font-size: calc(1em + 1.5vw);
  color: #5588e6;

  &.marked {
    background-color: #5588e6;
    font-weight: bold;
    color: #ffffff;
    padding: 15px;
  }

  .marked-only {
    background-color: #2af6ff;
    font-weight: bold;
    padding: 15px;
  }
}

.mt110 {
  margin-top: 110px;
}

.header {
  padding: 10px 45px;
  display: flex;
  background-color: #ffffff;
  height: auto;
  justify-content: space-between;
  flex-wrap: wrap;

  .menu {
    display: flex;

    .item-menu {
      font-weight: bolder;
      margin-left: 40px;
      margin-top: 10px;
    }
  }

  .search {
    .searchInpLabel {
      &:before {
        content: "";
        display: block;
        width: 16px;
        background-size: 16px;
        height: 16px;
        background-image: url(/assets/images/magnification.svg);
        margin-left: -25px;
        position: absolute;
        top: 8px;
      }
    }
    .searchInp {
      border-radius: 20px;
      border: 2px solid #5588e6;
      margin-left: 10px;
      outline: none;
      padding: 5px 15px;
      height: 30px;
      width: 170px;
    }

    div {
      position: relative;
    }

    .item-menu {
      font-weight: bold;
    }

    display: flex;
    margin-top: 7px;
  }

  .connectWallet {
    margin-left: 10px;
  }

  .connectWalletBl {
    margin-left: 10px;

    .btn.btn-primary.btn-menu.just-center {
      border: 2px solid #213458;
      background: #ffffff;
      color: #5588e6;
    }
  }
  .header-logo {
    width: 155px;
    height: 100%;
    background-image: url("/assets/images/logo-link.png");
    background-size: 155px;
    background-repeat: no-repeat;
    background-position: center;
  }
}

[data-tooltip] {
  z-index: 100;
  position: relative; /* Относительное позиционирование */
  cursor: help;
}

[data-tooltip]::after {
  content: attr(data-tooltip); /* Выводим текст */
  position: absolute; /* Абсолютное позиционирование */
  width: 300px; /* Ширина подсказки */
  left: -10em;
  top: 2em; /* Положение подсказки */
  background: #3989c9; /* Синий цвет фона */
  color: #ffffff; /* Цвет текста */
  padding: 0.5em; /* Поля вокруг текста */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Параметры тени */
  pointer-events: none; /* Подсказка */
  opacity: 0; /* Подсказка невидима */
  transition: 1s; /* Время появления подсказки */
}

[data-tooltip]:hover::after {
  opacity: 1; /* Показываем подсказку */
  top: 2em; /* Положение подсказки */
  left: -10em;
}

.cardImg {
  width: 65%;
  margin: 0 auto;
  margin-top: 50px;
  justify-content: space-between;
  margin-bottom: 50px;

  .frontcard {
    display: inline-block;
    width: 280px;
    height: 400px;
    border: 1px solid red;
    text-align: center;
    vertical-align: middle;
  }

  .backendcard {
    width: 280px;
    height: 400px;
    border: 1px solid red;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
  }
}

.p-t-50 {
  padding-top: 50px;
}

.p-t-40 {
  padding-top: 40px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-t-10 {
  padding-top: 10px;
}

.p-t-5 {
  padding-top: 5px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-b-15 {
  padding-bottom: 15px;
}

.p-b-10 {
  padding-bottom: 10px;
}

.p-b-6 {
  padding-bottom: 6px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-10 {
  padding-left: 10px;
}

.m-l-20 {
  margin-left: 20px;
}

.f-s-20 {
  font-size: 20px;
}

.end {
  text-align: end;
}

.grid {
  display: grid;
}

.center {
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
}

.right {
  text-align: right;
  text-align: -moz-right;
  text-align: -webkit-right;
}

.left {
  text-align: left;
  text-align: -moz-left;
  text-align: -webkit-left;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.align-self-center {
  align-self: center;
}

.space-evenly {
  display: flex;
  justify-content: space-evenly;
}

.space-center {
  display: flex;
  justify-content: center;
}

div.solid {
  border-top: 2px solid #b7b7b747;
  width: 100%;
}

.btn-menu {
  overflow: hidden;
  display: inline-flex;
  align-items: stretch;
  border-radius: 25px;
  width: -webkit-fill-available;
  width: -moz-available;
  font-size: 16px;
  height: 45px;
  font-weight: 600;
  background: #f8f7fb;
  color: #5588e6;
  border: 2px solid #5588e6;
  align-items: center;
  justify-content: center;

  &.btn-header {
    border-radius: 18px;
    padding: 0 15px;
    margin: initial;
  }

  .separator {
    border: solid #5588e6;
    border-width: 1px;
    height: 30px;
    margin: 0 10px;
  }

  .separator-fake {
    border: none;
    border-width: unset;
    height: unset;
    margin: 0 10px;
  }
}

span.icon {
  content: "";
  display: inline-block;
}

.icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: transparent;
  width: 30px;
  height: 30px;

  &.profile {
    background-image: url("/assets/images/icons/1.svg");
  }

  &.box {
    background-image: url("/assets/images/icons/2.svg");
  }

  &.wallet {
    background-image: url("/assets/images/icons/3.svg");
  }

  &.lens {
    background-image: url("/assets/images/icons/4.svg");
  }

  &.binoculars {
    background-image: url("/assets/images/icons/5.svg");
  }

  &.crown {
    background-image: url("/assets/images/icons/6.svg");
  }

  &.light {
    background-image: url("/assets/images/icons/7.svg");
  }

  &.prize {
    background-image: url("/assets/images/icons/8.svg");
  }

  &.fire {
    background-image: url("/assets/images/icons/9.svg");
  }

  &.graph {
    background-image: url("/assets/images/icons/10.svg");
  }

  &.filter {
    background-image: url("/assets/images/icons/11.svg");
  }

  &.collections {
    background-color: #213458;
    -webkit-mask-image: url("/assets/images/nav/collections.svg");
    mask-image: url("/assets/images/nav/collections.svg");

    .dark-mode & {
      background-color: #ffffff;
    }
  }

  &.auctions {
    background-color: #213458;
    -webkit-mask-image: url("/assets/images/nav/auctions.svg");
    mask-image: url("/assets/images/nav/auctions.svg");

    .dark-mode & {
      background-color: #ffffff;
    }
  }

  &.staking {
    background-color: #213458;
    -webkit-mask-image: url("/assets/images/nav/staking.svg");
    mask-image: url("/assets/images/nav/staking.svg");

    .dark-mode & {
      background-color: #ffffff;
    }
  }

  &.wallets {
    background-color: #213458;
    -webkit-mask-image: url("/assets/images/nav/wallets.svg");
    mask-image: url("/assets/images/nav/wallets.svg");

    .dark-mode & {
      background-color: #ffffff;
    }
  }

  &.duels {
    background-color: #213458;
    -webkit-mask-image: url("/assets/images/nav/duels.svg");
    mask-image: url("/assets/images/nav/duels.svg");

    .dark-mode & {
      background-color: #ffffff;
    }
  }

  &.user {
    background-color: #007bff;
    -webkit-mask-image: url("/assets/images/nav/user.svg");
    mask-image: url("/assets/images/nav/user.svg");

    .dark-mode & {
      background-color: #ffffff;
    }
  }

  &.star {
    background-color: #E2DA24;
    background-repeat: no-repeat;
    -webkit-mask-image: url("/assets/images/nav/star.svg");
    mask-image: url("/assets/images/nav/star.svg");

    .dark-mode & {
      background-color: #E2DA24;
    }
  }

  &.basket {
    background-image: url("/assets/images/nav/basket.svg");
  }

  &.ethereum {
    background-image: url("/assets/images/nav/eth.svg");
  }

  &.polygon {
    background-image: url("/assets/images/icons/polygon.svg");
  }

  &.search {
    background-image: url("/assets/images/nav/search.svg");
  }

  &.logo {
    background-image: url("/assets/images/logo-small-inverted.svg");

    @media only screen and (min-width: 1024px) {
      background-image: url("/assets/images/logo-link.png");
    }
  }
  &.digi-currency {
    background-image: url("/assets/images/logo-small-inverted.svg");
  }

  &.info-icon {
    background-image: url("/assets/images/nav/info-icon.svg");
  }

  &.forsale {
    background-image: url("/assets/images/nav/for-sale.svg");
  }

  &.logo-inverse {
    background-image: url("/assets/images/logo-small.svg");
  }

  &.safe {
    background-image: url("/assets/images/nav/safe.svg");
  }

  &.nft {
    background-image: url("/assets/images/nav/nft.svg");
  }

  &.fav {
    background-image: url("/assets/images/nav/fav.svg");
  }

  &.arrow {
    background-color: #213458;
    -webkit-mask-image: url("/assets/images/nav/arrow.svg");
    mask-image: url("/assets/images/nav/arrow.svg");

    .dark-mode & {
      background-color: #ffffff;
    }
  }
  &.usdt {
    background-image: url("/assets/images/nav/usdt.svg");
  }

  &.chainlink {
    background-image: url("/assets/images/icons/chainlink-link-logo.svg");
  }

  &.usdc {
    background-image: url("/assets/images/nav/usdc.svg");
  }
}

.section-title {
  font-family: "Avenir LT Std";
  font-weight: bold;
  color: #213458;
  font-size: 18px;

  .dark-mode & {
    color: #ffffff !important;
  }

  .icon {
    height: 18px;
    width: 18px;
    margin-right: 8px;
  }
}

.section-subtitle {
  color: #213458;
  font-size: 16px;
  .dark-mode & {
    color: #ffffff;
  }
}

.not-clickable {
  cursor: default !important;
  pointer-events: none;
}

.page-top {
  margin-top: 1.5rem;
  position: relative;

  @media (min-width: 1025px) {
    margin-top: 80px;
  }
}

.full-width {
  width: 100%;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.separate {
  border-top: 1px solid darken(white, 15%);
  padding-top: 2rem;
}

.c-card-container {
  display: flex;
  height: 100%;
  width: 100%;
}


.c-page-title {
  display: none;

  @media only screen and (min-width: 1024px) {
    display: flex;
  }
}

.c-about__description {
  display: flex;

  .dark-mode & {
    color: white;
  }
}

.btn-primary {
  padding-top: 0.6rem;
  border: 1px solid #213458;
  align-self: center;

  .dark-mode & {
    border: 1px solid white;
  }
}


.bold {
  font-weight: bold;
}

.scroll-container {
  @media only screen and (max-width: 786px) {
    min-width: 150%;
    overflow: scroll;
  }
}

.mobile-hidden {
  display: none;

  @media only screen and (min-width: 786px) {
    display: flex;
  }
}


.panel-body {
  padding-left: 1rem;
  padding-right: 1rem;

  .dark-mode & {
    color: #fff;
  }
}

.drop-zone {
  border: 2px dotted #0782d0;
  border-radius: 30px;
  height: auto !important;;
  margin: auto;
  padding: 2rem !important;
}


.visually-hidden {
  visibility: hidden;
  position: absolute;
  left: -999999px;
}

//
// ================================
//  Card
// ================================
//
.c-card {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid lighten(#213458, 60%);
  box-shadow: 0px 0px 3px 1px rgba(000, 000, 000, 0.15);
  border-radius: 16px;
  background-color: darken(#fdfdfd, 1%);
  margin-bottom: 1.5rem;

/*   &.back {
    @media only screen and (min-width: 1440px) {
      flex-direction: row;
    }
  } */

  :host-context(.dark-mode) & {
    border: 1px solid darken(#5588e6, 20%);
    box-shadow: 0px 0px 3px 1px rgba(#ffffff, 0.45);
    background-color: #384969;
  }
}

.c-card__countdown {
  position: absolute;
  left: 0;
  bottom: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.25rem 0.4rem 1.25rem;
  color: #213458;
  font-weight: bold;
  background-color: white;
  border: 2px solid #5588e6;
  border-radius: 35px;

  .icon {
    width: 16px;
    height: 16px;
    margin-left: 0.5rem;

    :host-context(.dark-mode) & {
      background-color: #213458;
    }
  }
}

.c-card__top {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .c-card.auction & {
    margin-top: 2rem;
  }
}

.c-card__title {
  font-size: 21px;
  font-weight: 600;
  color: #5588e6;
  white-space: pre-wrap;

  @media only screen and (min-width: 576px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  span {
    @media only screen and (min-width: 576px) {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media only screen and (min-width: 576px) {
    font-size: 26px;
  }

  :host-context(.dark-mode) & {
    color: #fff;
  }
}

.c-card__description {
  white-space: normal;
  word-break: break-word;
  color: grey;

  :host-context(.dark-mode) & {
    color: #fff;
  }
}

::ng-deep .ngxImageZoomContainer > img {
  max-height: 100%;
}

::ng-deep .ngxImageZoomContainer {
  height: 100% !important;
  text-align: center;
}

.c-card__figure__video {
  position: relative;
  padding: 1rem;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: contain;
  &.bg-video {
    background-color: #F3F6FA;
  }
}

.c-card__timer {
  position: absolute;
  min-height: 36px;
  font-size: 14px;
  font-weight: 600;
  color: #213458;
  bottom: -12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  background-color: white;
  line-height: 1;
  padding: 0.35rem 0.5rem;
  border: 1px solid #5588e6;
  border-radius: 50px;
  width: auto;
}

.c-card__figure {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 210px;
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
  border: 1px solid #dee3ea;

  .back & {
    @media only screen and (min-width: 1440px) {
      margin: 0.5rem;
    }
    &:nth-child(1) {
      margin-bottom: 0.5rem;
    }
  }

  @media only screen and (min-width: 414px) {
    height: 280px;
  }
}

.c-card__arrow {
  position: absolute;
  right: 1rem;
  bottom: 0.75rem;
  width: 30px;
  height: 30px;
  color: #5588e6;
}

.c-card__safe {
  width: 20px;
  height: 20px;
}

.c-card__auction-icon {
  width: 15px;
  height: 15px;
  margin-left: 0.5em;
}

.c-card__info {
  font-weight: bold;
  font-size: 14px;
  color: #213458;

  :host-context(.dark-mode) & {
    color: white;
  }
}

.c-card__body {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.c-card__arrow {
  position: absolute;
  right: 17px;
  bottom: 17px;
}

.c-card__price {
  display: flex;
  color: #213458;
  font-size: 14px;
  font-weight: bold;
  min-width: 65px;
  margin-left: auto;
  justify-content: flex-end;
  align-items: center;

  :host-context(.dark-mode) & {
    color: white;
  }

  .icon {
    width: 1.25rem;
    height: 1.25rem;
    min-width: 20px;
  }
}

.c-card__button {
  width: 100%;
  border: 1px solid #213458;
  border-radius: 6px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 1rem;
  font-size: 0.8rem;

  @media only screen and (min-width: 1024px) {
    font-size: 1rem;
  }

  :host-context(.dark-mode) & {
    border: 1px solid #fff;
  }
}

.c-card__footer-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.c-card__list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1rem;
  color: #213458;

  :host-context(.dark-mode) & {
    color: #fff;
  }

  li {
    padding: 0;
    margin-right: 1rem;
  }
}

.c-card__burned {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  background: red;
  padding: 1rem 3rem;
  border-radius: 16px;
  text-align: center;
}

.c-card__owner {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: white;
  background-color: #5588e6;
}

.c-card__owner {
  z-index: 10;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  padding-top: 0.35rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 10px;
  color: white;
  background-color: #5588e6;
}

.traits {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.trait {
  display: flex;
  flex-direction: column;
  text-align: center;
  list-style-type: none;
  margin: 0;
  border-radius: 1rem;
  border: 1px solid #5588e6;
  padding: 1rem;
  margin-bottom: 1rem;
}

.ngx-file-drop__content,
.ngx-file-drop__drop-zone {
  height: auto !important;
  padding: 1rem;
}

.border-style-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1.25rem 0.4rem 1.25rem;
  color: #213458;
  font-weight: bold;
  background-color: white;
  border: 2px solid #5588e6;
  border-radius: 35px;
  width: max-content;
}

.error-alert {
  z-index: 98;
  position: fixed;
  width: 100%;
  top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.45rem;
  padding-bottom: 0.45rem;
  background-color: #5588e6;
  font-size: 14px;
  font-weight: 800;
  border-radius: 0;
}

// SEARCH


.c-search__svg {
  width: 17px;
  height: 17px;
}

.c-search {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;

  @media only screen and (min-width: 1024px) {
    max-width: 620px;
  }
}

.c-search-bar {
  border: 1px solid #5588e6;
  border-radius: 50px;
  height: 41px;
  width: 100%;
  padding-left: 2rem;
  -webkit-appearance: none;

  &:focus,
  &:active {
    outline: none;
    border-radius: 50px;
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.45);
  }

  &::placeholder {
    position: relative;
    font-size: 14px;
    font-weight: bold;
    top: 2px;
  }
}

.c-search-button {
  border: none;
  max-height: 18px;
  background-color: transparent;
  position: absolute;
  left: 0.5rem;
}

.c-search-submit {
  position: absolute;
  right: 4px;
  border-radius: 18px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  padding-left: 0.95rem;
  padding-right: 0.95rem;
  padding-top: 0.35rem;
  padding-bottom: 0.3rem;
  background-color: #5588e6;
  border: 0;

  :host-context(.dark-mode) & {
    background-color: #213458;
  }

  @media only screen and (min-width: 1024px) {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    padding-top: 0.48rem;
    padding-bottom: 0.35rem;
  }
}

.loading-component {
  display: flex;
  position: absolute;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 90px);
}
.input-group{
  justify-content: center;
}
.form-outline{
  padding-right: 0%;
  margin-top: 27px;
}
.form-outline input{
  width: 100%;
  border-radius: 4px;
  height: 40px;
}
.input-group button{
  border-radius: 4px;
  padding-bottom: 4px;
  margin-top: -4px;
  margin-top: 25px;
}
.input-group{
  justify-content: center;
}
.form-outline{
  padding-right: 0%;
  margin-top: 27px;
}
.form-outline input{
  width: 100%;
  border-radius: 4px;
  height: 40px;
}
.input-group button{
  border-radius: 4px;
  padding-bottom: 4px;
  margin-top: -4px;
  margin-top: 25px;
}
